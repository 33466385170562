<template>
  <v-data-table
    :headers="headers"
    :items="rows"
  >
    <template v-slot:body.append="{ header, item }">
      <tr>
        <td
          colspan="3"
          class="text-right"
        >
          Running Totals
        </td>
        <td>
          {{ totalUnits }}
        </td>
        <td>
          {{ totalBoxes }}
        </td>
      </tr>
    </template>
  </v-data-table>
</template>
<script>
export default {
  name: 'WarehousePieceCountsTable',
  props: {
    rows: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      headers: [
        { text: 'Purchase ID', value: 'purchase_id' },
        { text: 'Check In ID', value: 'checkin_id' },
        { text: 'Yard', value: 'yard_name' },
        { text: 'Total Units', value: 'units' },
        { text: 'Boxes', value: 'boxes' }
      ]
    }
  },
  computed: {
    totalUnits () {
      return this.rows.reduce((total, row) => total + row.units, 0)
    },
    totalBoxes () {
      return this.rows.reduce((total, row) => total + row.boxes, 0)
    }
  }
}
</script>
