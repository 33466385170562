<template>
  <material-card
    color="orange"
    class="px-5 py-3"
  >
    <template v-slot:heading>
      <base-title>
        Piece Counts
        <info-tooltip>
          Displays same check ins that would appear in Piece Counts Page
        </info-tooltip>
      </base-title>
      <v-tabs
        v-model="pieceCountTabs"
        show-arrows
        background-color="transparent"
        slider-color="white"
      >
        <v-tab
          v-for="(tab, index) in tabs"
          :key="index"
        >
          {{ tab[0] }}
        </v-tab>
      </v-tabs>
    </template>
    <v-card-text>
      <v-tabs-items
        v-model="pieceCountTabs"
      >
        <v-tab-item
          v-for="(tab, index) in tabs"
          :key="index"
        >
          <warehouse-piece-counts-table
            :rows="tab[1]"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </material-card>
</template>
<script>
import WarehousePieceCountsTable from './WarehousePieceCountsTable'
import { mapGetters } from 'vuex'

export default {
  name: 'WarehousePieceCounts',
  components: { WarehousePieceCountsTable },
  data: function () {
    return {
      pieceCountTabs: 0
    }
  },
  computed: {
    /**
     * Only shows piece counts if there are rows to show
     */
    tabs () {
      const tabs = []
      tabs.push(['All', this.getRows()])
      for (const location of this.$store.getters['getConfigLocations']) {
        const rows = this.getRows([location.code])
        if (rows.length) tabs.push([location.name, rows])
      }
      return tabs
    },
    ...mapGetters('currentPurchases', [
      'checkIns'
    ])
  },
  methods: {
    getRows (filters) {
      return this.checkIns.filter(ci => {
        if (!filters) return true
        return filters.includes(ci.purchase.division_code)
      }).map(ci => {
        return {
          purchase_id: ci.purchase.id,
          checkin_id: ci.id,
          yard_name: ci.yard_name,
          units: ci.total_counts.total,
          boxes: this.checkInSkidCounts(ci)
        }
      })
    },
    checkInSkidCounts (checkIn) {
      if (checkIn.skids.length) return checkIn.skids.length
      if (checkIn.preliminary_skid_count) return checkIn.preliminary_skid_count
      return 0
    }
  },
  async mounted () {
    await this.$store.dispatch('currentPurchases/fetchPieceCount')
    await this.$store.dispatch('fetchConfig') // load in the config data for locations
  }
}
</script>
